<template>
  <div class="clAdvice">
    <div class="box">
      <p>镜片</p>
      <el-select class="xz" v-model="postData.CL_Recheck_HS_glass" filterable allow-create  placeholder="请选择">
        <el-option
            v-for="item in jp"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>

    <div class="box">
      <p>伴侣盒</p>
      <el-select class="xz" v-model="postData.CL_Recheck_HS_Cnkaite" filterable allow-create  placeholder="请选择">
        <el-option
            v-for="item in blh"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>

    <div class="box">
      <p>吸棒</p>
      <el-select class="xz" v-model="postData.CL_Recheck_HS_stick" filterable allow-create  placeholder="请选择">
        <el-option
            v-for="item in blh"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>

    <div class="box">
      <p>其他</p>
      <el-input
          v-model="postData.CL_Recheck_HS_other"
          class="sr"
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          >
      </el-input>
    </div>

    <div class="box">
      <p>复查时间</p>
      <el-date-picker
          v-model="postData.CL_Recheck_HS_recheckTime"
          type="datetime"
          placeholder="选择日期">
      </el-date-picker>
    </div>

    <div class="box">
      <p>医嘱</p>
      <el-input
          v-model="postData.CL_Recheck_doctorSay"
          class="sr"
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
      >
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "clAdvice",
  data() {
    return {
      value1: '',
      value: '',
      jp: [
        {
          value: '继续佩戴',
          label: '继续佩戴'
        },
        {
          value: '清洗',
          label: '清洗'
        },
        {
          value: '返厂打磨',
          label: '返厂打磨'
        },
        {
          value: '更换',
          label: '更换'
        }
      ],
      blh: [
        {
          value: '正常使用',
          label: '正常使用'
        },
        {
          value: '需要清洗',
          label: '需要清洗'
        },
        {
          value: '到期更换',
          label: '到期更换'
        }
      ],
      postData: {
        CL_Recheck_HS_glass: "",
        CL_Recheck_HS_Cnkaite: "",
        CL_Recheck_HS_stick: "",
        CL_Recheck_HS_other: "",
        CL_Recheck_HS_recheckTime: "",
        CL_Recheck_doctorSay: "",
      }
    }
  },
  watch: {
    postData: {
      handler(n, o) {
        this.$store.commit('corneaContact/upCorneaReview', n)
      },
      deep: true
    },
    "$store.state.corneaContact.isfcshowdata": {
      deep: true,
      handler(n, o) {
        if (!n) {return false}
        for (let key in this.postData) {
          this.postData[key] = this.$store.state.corneaContact.corneaReview[key]
        }
      }
    }
  },
  created() {
    this.postData = this.$store.state.corneaContact.corneaReview
  },
}
</script>

<style scoped lang="scss">
  .box {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: .3rem 0;
    p {
      width: 4em;
      text-align: right;
      padding-right: .2rem;
    }
    .xz {width: 13vw;}
    .sr {
      width: 60vw;
    }
  }
</style>
